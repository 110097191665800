
import { defineComponent, PropType } from 'vue';
import { useRouter } from 'vue-router';

import AppButton from '@/components/stateless/AppButton.vue';
import AppUserAvatar from '@/components/stateless/AppUserAvatar.vue';

import { IChild } from '@/types';
import { routesNames } from '@/router';

export default defineComponent({
  name: 'Children',

  components: { AppButton, AppUserAvatar },

  props: {
    children: {
      type: Array as PropType<IChild[]>,
      required: true
    }
  },

  setup() {
    const router = useRouter();

    function onChildClick (id: string) {
      router.push({ name: routesNames.children, query: { id } });
    }

    return {
      router,
      routesNames,
      
      onChildClick,
    };
  }

});
