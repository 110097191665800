
  import { defineComponent, onMounted, PropType, ref, onBeforeUnmount } from 'vue';

  import AppIcon from '@/components/stateless/AppIcon.vue';

  import { INotificationRes } from '@/types';

  export default defineComponent({
    name: 'NotificationItem',

    components: { AppIcon },

    props: {
      notification: {
        type: Object as PropType<INotificationRes>,
        required: true
      }
    },

    setup() {
      const description = ref();
      const isTruncated = ref<boolean>(false);
      const showMore = ref<boolean>(false);

      function handleTruncate() {
        isTruncated.value = description.value.offsetHeight < description.value.scrollHeight;
      }

      function showMoreToggle() {
        showMore.value = !showMore.value;
      }

      onMounted(() => {
        handleTruncate();
        window.addEventListener('resize', handleTruncate);
      });

      onBeforeUnmount(() => {
        window.removeEventListener('resize', handleTruncate);
      });

      return {
        description,
        isTruncated,
        showMore,

        showMoreToggle
      };
    }
  });
