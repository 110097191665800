<template>
  <div class="bg-white p-14 sm:p-20 md:p-25 rounded-5 h-full">
    <p class="text-dark-cl-20 text-md sm:text-1xl">Children</p>
  
    <div class="children-list-wrap relative" :class="{'is-scroll': children.length > 2}">
      <ul class="max-h-140 py-8 pr-8 overflow-y-auto custom-scrollbar">
        <li v-for="child of children" :key="child.id" class="child mb-16 sm:mb-20 last:mb-0">
          <div class="flex items-center cursor-pointer" @click="onChildClick(child.id)">
            <AppUserAvatar 
              :username="child.firstName + ' ' + child.lastName" 
              class="user-avatar text-md min-h-40 min-w-40 mr-18"
            />
            <span class="name text-md text-grey-fp-70 truncate">{{ child.firstName + ' ' + child.lastName }}</span>
          </div>
        </li>
      </ul>
    </div>

    <AppButton 
      type="secondary" 
      icon="plus" 
      size="large"
      icon-size="16"
      icon-classes="h-40 w-40"
      custom-class="h-40 mt-10"
      @click="router.push({ name: routesNames.addChild })"
    >
      <span class="text-md ml-6">
        Add Child
      </span>
    </AppButton>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useRouter } from 'vue-router';

import AppButton from '@/components/stateless/AppButton.vue';
import AppUserAvatar from '@/components/stateless/AppUserAvatar.vue';

import { IChild } from '@/types';
import { routesNames } from '@/router';

export default defineComponent({
  name: 'Children',

  components: { AppButton, AppUserAvatar },

  props: {
    children: {
      type: Array as PropType<IChild[]>,
      required: true
    }
  },

  setup() {
    const router = useRouter();

    function onChildClick (id: string) {
      router.push({ name: routesNames.children, query: { id } });
    }

    return {
      router,
      routesNames,
      
      onChildClick,
    };
  }

});
</script>

<style lang="scss" scoped>
.children-list-wrap {

  &.is-scroll {

    &::before {
      @apply w-full h-10 -top-1 left-0 absolute;
      content: '';
      transform: rotate(-180deg);
      opacity: 0.9;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
    }
  
    &::after {
      @apply w-full h-10 -bottom-1 left-0 absolute;
      content: '';
      opacity: 0.9;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
    }
  }
}

.child {

  &:hover {
    .user-avatar {
      @apply bg-blue-fp-50;
    }
    .name {
      @apply opacity-80;
    }
  }
}
</style>