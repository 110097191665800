import { IRequestParams } from '@/types';

export const ACTIVITIES_PAGINATION_LIMIT = 10;
export const EXPLORE_ACTIVITIES_CATEGORIES = 10;

export const registeredActivitiesParams =
  (offset?: number, limit = ACTIVITIES_PAGINATION_LIMIT): IRequestParams => ({
    params: {
      filter: {
        limit,
        offset,
        where: {
          endDate: { $gt: new Date().toISOString() }
        },
        order: [
          [
            "placementFlag",
            "DESC"
          ],
          [
            "startDate", "asc"
          ]
        ],
        include: [
          {
            association: "registrations",
            required: true,
            where: {
              childId: null,
              status: {
                $in: [
                  "initialCancelled",
                  "activityCancelled",
                  "active"
                ]
              }
            }
          },
          {
            association: "category"
          },
          { model: "category" }
        ]
      },
      includeCount: true
    }
  });

export const activitiesWithoutRegistrationsParams =
  (offset?: number, limit = EXPLORE_ACTIVITIES_CATEGORIES): IRequestParams => ({
    params: {
      filter: {
        limit,
        offset,
        order: [
          [
            "placementFlag",
            "DESC"
          ],
          [
            "startDate", "asc"
          ]
        ],
        where: {
          endDate: { $gt: new Date().toISOString() },
          $and: [
            {
              $or: [
                {
                  "$registrations.status$": "initial"
                },
                {
                  "$registrations.id$": null
                }
              ]
            },
            {
              status: "active"
            }
          ]
        },
        include: [
          {
            association: "registrations",
            where: {
              childId: null,
              status: {
                $ne: "cancelled"
              }
            }
          },
          {
            association: "category"
          },
          { model: "category" }
        ]
      },
      includeCount: true
    }
  });

export const homeNotifications = (limit?: number, offset?: number): IRequestParams => ({
  params: {
    filter: {
      order: [
        ["createdAt", "DESC"]
      ],
      limit,
      offset
    }
  }
});

