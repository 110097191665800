<template>
  <!-- A C T I V I T I E S   D E S K T O P -->
  <div class="hidden md:block">
    <RegisteredActivities 
      :activities-list="registeredActivities" 
      :headers="tableHeaders" 
      title="You Are Signed Up For The Following Activities"
      :external-link="externalLink"
      :open-modal="openCancellationModal"
      @browse="handleBrowsing"
      @cancel="handleCancellation"
      @discard="discardCancellation"
      @confirm="confirmCancellation"
    >
      <!-- P A G I N A T I O N -->
      <template
        v-if="registeredActivities.length"
        #pagination
      >
        <div class="flex justify-between items-center py-20 border-t border-grey-fp-10">
          <PaginationCounter
            :offset="myActivitiesOffset"
            :limit="ACTIVITIES_PAGINATION_LIMIT"
            :total="registeredCount"
            class="text-posey-blue"
          />

          <v-pagination
            :key="registeredCount"
            :page-size="ACTIVITIES_PAGINATION_LIMIT"
            layout="prev, pager, next"
            hide-on-single-page
            :total="registeredCount"
            @current-change="(val) => handlePageChanged('myActivitiesPage', val)"
          />
        </div>
      </template>
    </RegisteredActivities>

    <UpcomingActivities 
      :activities-list="activitiesList" 
      :headers="tableHeaders"
      title="Upcoming Activities"
      :external-link="externalLink"
      :open-modal="openRegisterModal"
      @browse="handleBrowsing"
      @confirm="confirmRegistration"
      @discard="discardRegistration"
      @register="handleRegistration"
    >
      <!-- P A G I N A T I O N -->
      <template
        v-if="activitiesList.length"
        #pagination
      >
        <div class="flex justify-between items-center py-20 border-t border-grey-fp-10">
          <PaginationCounter
            :offset="exploreActivitiesOffset"
            :limit="EXPLORE_ACTIVITIES_CATEGORIES"
            :total="withoutRegistrationCount"
            class="text-posey-blue"
          />

          <v-pagination
            :key="withoutRegistrationCount"
            :page-size="EXPLORE_ACTIVITIES_CATEGORIES"
            layout="prev, pager, next"
            hide-on-single-page
            :total="withoutRegistrationCount"
            @current-change="(val) => handlePageChanged('exploreActivitiesPage', val)"
          />
        </div>
      </template>
    </UpcomingActivities>
  </div>

  <!-- A C T I V I T I E S   M O B I L E -->
  <div class="block md:hidden">
    <AppTabs v-model="activeTab" custom-tab-classes="w-full text-center" :items="tabs" class="mb-20" />

    <RegisteredActivities 
      v-if="activeTab.value === 'registered'"
      :activities-list="registeredActivities" 
      :headers="tableHeaders" 
      title="You Are Signed Up For The Following Activities"
      :external-link="externalLink"
      :open-modal="openCancellationModal"
      @browse="handleBrowsing"
      @cancel="handleCancellation"
      @discard="discardCancellation"
      @confirm="confirmCancellation"
    >
      <template
        v-if="registeredCount > registeredActivities.length"
        #pagination
      >
        <div class="flex mt-10">
          <AppButton
            type="primary"
            class="mr-10 px-20 border-primary w-full"
            plain
            @click="$emit('load-more-activities', 'registered')"
          >
            Load
            {{ registeredCount - registeredActivities.length >= ACTIVITIES_PAGINATION_LIMIT 
              ? ACTIVITIES_PAGINATION_LIMIT
              : registeredCount - registeredActivities.length }}
            more
          </AppButton>
        </div>
      </template>
    </RegisteredActivities>

    <UpcomingActivities 
      v-else
      :activities-list="activitiesList" 
      :headers="tableHeaders"
      title="Upcoming Activities"
      :external-link="externalLink"
      :open-modal="openRegisterModal"
      @browse="handleBrowsing"
      @confirm="confirmRegistration"
      @discard="discardRegistration"
      @register="handleRegistration"
    >
      <template
        v-if="withoutRegistrationCount > activitiesList.length"
        #pagination
      >
        <div class="flex mt-10">
          <AppButton
            type="primary"
            class="mr-10 px-20 border-primary w-full"
            plain
            @click="$emit('load-more-activities', 'upcoming')"
          >
            Load
            {{ withoutRegistrationCount - activitiesList.length >= ACTIVITIES_PAGINATION_LIMIT 
              ? ACTIVITIES_PAGINATION_LIMIT
              : withoutRegistrationCount - activitiesList.length }}
            more
          </AppButton>
        </div>
      </template>
    </UpcomingActivities>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, PropType, reactive, toRefs } from 'vue';
  import { useScreen } from 'vue-screen';

  import UpcomingActivities from '@/components/activities-rsvp/UpcomingActivities.vue';
  import RegisteredActivities from '@/components/activities-rsvp/RegisteredActivities.vue';
  import AppTabs from '@/components/stateless/AppTabs.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import PaginationCounter from '@/components/PaginationCounter.vue';

  import { IActivity, ITableHeaders, TIndexedObject } from '@/types';
  import { useActivityRegistration } from '@/components/activities-rsvp/useActivityRegistartion';
  import { 
    ACTIVITIES_PAGINATION_LIMIT,
    EXPLORE_ACTIVITIES_CATEGORIES,
  } from '@/views/home/api-params';

  export default defineComponent({
    name: 'Activities',

    components: { UpcomingActivities, RegisteredActivities, AppTabs, PaginationCounter, AppButton },

    props: {
      activitiesList: {
        type: Array as PropType<IActivity[]>,
        required: true
      },

      registeredActivities: {
        type: Array as PropType<IActivity[]>,
        required: true
      },

      registeredCount: {
        type: Number,
        default: 0
      },

      withoutRegistrationCount: {
        type: Number,
        default: 0
      }
    },

    emits: [ 'update', 'load-more-activities' ],

    setup(props, { emit }) {
      const state = reactive({ activeTab: {}});
      const screen = useScreen();

      const desktopPaginationPages = reactive({
        myActivitiesPage: 0,
        exploreActivitiesPage: 0
      }) as TIndexedObject;

      const tabs: TIndexedObject[] = [
        { value: 'available', label: 'Available'},
        { value: 'registered', label: 'Registered'},
      ];

      const { activeTab } = toRefs(state);

      const myActivitiesOffset = computed<number>(() => {
        return desktopPaginationPages.myActivitiesPage * ACTIVITIES_PAGINATION_LIMIT;
      });

      const exploreActivitiesOffset = computed<number>(() => {
        return desktopPaginationPages.exploreActivitiesPage * ACTIVITIES_PAGINATION_LIMIT;
      });

      function handlePageChanged(activitiesType: string, page: number) {
        desktopPaginationPages[activitiesType] = page - 1;

        emit('update', activitiesType, myActivitiesOffset.value, exploreActivitiesOffset.value);
      }

      const {
        externalLink,
        openCancellationModal,
        openRegisterModal,
        handleCancellation,
        confirmCancellation,
        discardCancellation,
        handleBrowsing,

        confirmRegistration,
        discardRegistration,
        handleRegistration
      } = useActivityRegistration({ 
        updateActivitiesCb: () => handleUpdateActivities()
      });

      function resetDesktopPage(value: 'cancelation' | 'registration') {
        if (value === 'cancelation') {
          if (props.registeredActivities.length === 1) {
            desktopPaginationPages.myActivitiesPage = 0;
          }
        } else {
          if (props.activitiesList.length === 1) {
            desktopPaginationPages.exploreActivitiesPage = 0;
          }
        }
      }

      function handleUpdateActivities() {

        emit('update', 'both', myActivitiesOffset.value, exploreActivitiesOffset.value);

      }
      const tableHeaders = computed<ITableHeaders[]>(() => [
        { property: 'name', label: 'Activity Name', minWidth: 250 },
        { property: 'description', label: 'Description', minWidth: 500 },
        { property: 'startDate', label: 'Start Date', sortable: true, minWidth: 140 },
        { property: 'endDate', label: 'End Date', sortable: true, minWidth: 130 },
        { property: 'status', minWidth: 300, show: screen.width > 769 },
      ]);

      return {
        tableHeaders,
        activeTab,
        tabs,

        externalLink,
        openCancellationModal,
        openRegisterModal,
        myActivitiesOffset,
        exploreActivitiesOffset,

        ACTIVITIES_PAGINATION_LIMIT,
        EXPLORE_ACTIVITIES_CATEGORIES,

        handleCancellation,
        confirmCancellation: (id: number) => { 
          resetDesktopPage('cancelation');
          confirmCancellation(id); 
        },
        discardCancellation,
        handlePageChanged,

        handleBrowsing,

        confirmRegistration: (id: number) => { 
          resetDesktopPage('registration'); 
          confirmRegistration(id); 
        },
        discardRegistration,
        handleRegistration
      };
    }

  });
</script>

<style lang="scss" scoped>
  .activities-table {
    max-height: 500px;
  }
</style>