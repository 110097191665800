<template>
  <div class="bg-white p-14 sm:p-20 md:p-15 rounded-5 h-full">
    <p class="text-success text-md sm:text-1xl mb-10">Notifications</p>
    <div class="h-full overflow-auto notifications-list max-h-200 md:max-h-8/10 ">
      <div class="overflow-y-auto h-full">
        <p
          v-if="!notifications.length"
          class="text-md p-10"
        >
          You’re all caught up.
        </p>
        <div v-else class="h-full overflow-y-auto pt-10 px-10 pb-20 flex flex-col">
          <NotificationItem
            v-for="item of notifications"
            :key="item.id"
            :notification="item"
          />
          <AppButton
            v-if="notificationsCount > notifications.length"
            type="primary"
            class="px-20 border-primary self-start mt-10"
            plain
            size="mini"
            @click="$emit('view-more')"
          >
            View more
          </AppButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';

  import AppButton from '@/components/stateless/AppButton.vue';
  import NotificationItem from '@/views/home/components/notifications/NotificationItem.vue';

  import { INotificationRes } from '@/types';

  export default defineComponent({
    name: 'Notifications',

    components: { AppButton, NotificationItem },

    props: {
      notifications: {
        type: Array as PropType<INotificationRes[]>,
        default: () => []
      },

      notificationsCount: {
        type: Number,
        required: true
      }
    },

    emits: ['view-more']
  });
</script>

<style lang="scss" scoped>
@media (min-width: 769px) {
      
  .notifications-list {
    @apply relative;
    
    &::after {
      content: "";
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) -31%,
        #ffffff
      );
      @apply h-20 opacity-90 absolute bottom-0 left-0 right-6 z-1;
    }

    &::before {
      content: "";
      transform: rotate(-180deg);
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) -31%,
        #ffffff
      );
      @apply h-20 opacity-90 absolute top-0 left-0 right-6 z-1;
    }
  }
}
</style>