
  import { defineComponent, computed, PropType, reactive, toRefs } from 'vue';
  import { useScreen } from 'vue-screen';

  import UpcomingActivities from '@/components/activities-rsvp/UpcomingActivities.vue';
  import RegisteredActivities from '@/components/activities-rsvp/RegisteredActivities.vue';
  import AppTabs from '@/components/stateless/AppTabs.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import PaginationCounter from '@/components/PaginationCounter.vue';

  import { IActivity, ITableHeaders, TIndexedObject } from '@/types';
  import { useActivityRegistration } from '@/components/activities-rsvp/useActivityRegistartion';
  import { 
    ACTIVITIES_PAGINATION_LIMIT,
    EXPLORE_ACTIVITIES_CATEGORIES,
  } from '@/views/home/api-params';

  export default defineComponent({
    name: 'Activities',

    components: { UpcomingActivities, RegisteredActivities, AppTabs, PaginationCounter, AppButton },

    props: {
      activitiesList: {
        type: Array as PropType<IActivity[]>,
        required: true
      },

      registeredActivities: {
        type: Array as PropType<IActivity[]>,
        required: true
      },

      registeredCount: {
        type: Number,
        default: 0
      },

      withoutRegistrationCount: {
        type: Number,
        default: 0
      }
    },

    emits: [ 'update', 'load-more-activities' ],

    setup(props, { emit }) {
      const state = reactive({ activeTab: {}});
      const screen = useScreen();

      const desktopPaginationPages = reactive({
        myActivitiesPage: 0,
        exploreActivitiesPage: 0
      }) as TIndexedObject;

      const tabs: TIndexedObject[] = [
        { value: 'available', label: 'Available'},
        { value: 'registered', label: 'Registered'},
      ];

      const { activeTab } = toRefs(state);

      const myActivitiesOffset = computed<number>(() => {
        return desktopPaginationPages.myActivitiesPage * ACTIVITIES_PAGINATION_LIMIT;
      });

      const exploreActivitiesOffset = computed<number>(() => {
        return desktopPaginationPages.exploreActivitiesPage * ACTIVITIES_PAGINATION_LIMIT;
      });

      function handlePageChanged(activitiesType: string, page: number) {
        desktopPaginationPages[activitiesType] = page - 1;

        emit('update', activitiesType, myActivitiesOffset.value, exploreActivitiesOffset.value);
      }

      const {
        externalLink,
        openCancellationModal,
        openRegisterModal,
        handleCancellation,
        confirmCancellation,
        discardCancellation,
        handleBrowsing,

        confirmRegistration,
        discardRegistration,
        handleRegistration
      } = useActivityRegistration({ 
        updateActivitiesCb: () => handleUpdateActivities()
      });

      function resetDesktopPage(value: 'cancelation' | 'registration') {
        if (value === 'cancelation') {
          if (props.registeredActivities.length === 1) {
            desktopPaginationPages.myActivitiesPage = 0;
          }
        } else {
          if (props.activitiesList.length === 1) {
            desktopPaginationPages.exploreActivitiesPage = 0;
          }
        }
      }

      function handleUpdateActivities() {

        emit('update', 'both', myActivitiesOffset.value, exploreActivitiesOffset.value);

      }
      const tableHeaders = computed<ITableHeaders[]>(() => [
        { property: 'name', label: 'Activity Name', minWidth: 250 },
        { property: 'description', label: 'Description', minWidth: 500 },
        { property: 'startDate', label: 'Start Date', sortable: true, minWidth: 140 },
        { property: 'endDate', label: 'End Date', sortable: true, minWidth: 130 },
        { property: 'status', minWidth: 300, show: screen.width > 769 },
      ]);

      return {
        tableHeaders,
        activeTab,
        tabs,

        externalLink,
        openCancellationModal,
        openRegisterModal,
        myActivitiesOffset,
        exploreActivitiesOffset,

        ACTIVITIES_PAGINATION_LIMIT,
        EXPLORE_ACTIVITIES_CATEGORIES,

        handleCancellation,
        confirmCancellation: (id: number) => { 
          resetDesktopPage('cancelation');
          confirmCancellation(id); 
        },
        discardCancellation,
        handlePageChanged,

        handleBrowsing,

        confirmRegistration: (id: number) => { 
          resetDesktopPage('registration'); 
          confirmRegistration(id); 
        },
        discardRegistration,
        handleRegistration
      };
    }

  });
