<template>
  <div class="min-h-65 flex items-start border-b border-grey-fp-10 py-8 w-full flex-shrink-0">
    <div class="min-h-32 min-w-32 rounded-half bg-grey-fp-10 flex items-center justify-center">
      <AppIcon
        :name="notification.externalLink ? 'link' : 'notifications'"
        size="18"
        class="text-grey-fp-50"
      />
    </div>

    <div class="ml-12 w-full">
      <p
        ref="description"
        class="text-grey-fp-70 item-description"
        :class="{'line-clamp-2': !showMore}"
      >
        {{ notification.description }}
      </p>
      <span
        v-if="notification.externalLink"
        class="py-5 external-link-wrap"
      >
        <a
          :href="notification.externalLink"
          target="_blank"
          class="underline text-primary text-sm"
        >
          More info (external link)
        </a>
      </span>
      <div class="flex justify-between">
        <p class="text-posey-blue text-sm">{{ $filters.timeMillisToTimeAgo(notification.createdAt) }}</p>
        <p
          v-if="isTruncated"
          class="text-primary text-sm cursor-pointer"
          @click="showMoreToggle"
        >
          Show {{ showMore ? 'less' : 'more' }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, PropType, ref, onBeforeUnmount } from 'vue';

  import AppIcon from '@/components/stateless/AppIcon.vue';

  import { INotificationRes } from '@/types';

  export default defineComponent({
    name: 'NotificationItem',

    components: { AppIcon },

    props: {
      notification: {
        type: Object as PropType<INotificationRes>,
        required: true
      }
    },

    setup() {
      const description = ref();
      const isTruncated = ref<boolean>(false);
      const showMore = ref<boolean>(false);

      function handleTruncate() {
        isTruncated.value = description.value.offsetHeight < description.value.scrollHeight;
      }

      function showMoreToggle() {
        showMore.value = !showMore.value;
      }

      onMounted(() => {
        handleTruncate();
        window.addEventListener('resize', handleTruncate);
      });

      onBeforeUnmount(() => {
        window.removeEventListener('resize', handleTruncate);
      });

      return {
        description,
        isTruncated,
        showMore,

        showMoreToggle
      };
    }
  });
</script>

<style lang="scss" scoped>
  .item-description,
  .external-link-wrap {
    font-size: 13px;
  }
</style>