
  import { defineComponent, PropType } from 'vue';

  import AppButton from '@/components/stateless/AppButton.vue';
  import NotificationItem from '@/views/home/components/notifications/NotificationItem.vue';

  import { INotificationRes } from '@/types';

  export default defineComponent({
    name: 'Notifications',

    components: { AppButton, NotificationItem },

    props: {
      notifications: {
        type: Array as PropType<INotificationRes[]>,
        default: () => []
      },

      notificationsCount: {
        type: Number,
        required: true
      }
    },

    emits: ['view-more']
  });
