
  import { computed, defineComponent, onBeforeMount, reactive, toRefs, ref } from 'vue';
  import { useToast } from "vue-toastification";

  import GoalsList from '@/components/GoalsList.vue';
  import Banner from '@/components/Banner.vue';
  import Notifications from '@/views/home/components/notifications/Notifications.vue';
  import Children from '@/views/home/components/Children.vue';
  import Activities from '@/views/home/components//activities/Activities.vue';
  import RegistrationBanner from '@/components/RegistrationBanner.vue';

  import { vuex } from '@/store';
  import { myGoalsService, childrenService, notificationsService } from '@/services';
  import { IGoal, IChild, INotificationRes, IGoalsResponse, TIndexedObject } from '@/types';
  import { useActivityRegistration } from '@/components/activities-rsvp/useActivityRegistartion';
  import { 
    registeredActivitiesParams,
    activitiesWithoutRegistrationsParams,
    homeNotifications,
    ACTIVITIES_PAGINATION_LIMIT,
  } from '@/views/home/api-params';


  export default defineComponent({
    name: 'Home',

    components: { GoalsList, Notifications, Children, Activities, RegistrationBanner, Banner },

    setup() {
      const toast = useToast();
      const state = reactive({
        goalsList: [] as IGoal[],
        childrenList: [] as IChild[],
        notificationsList: [] as INotificationRes[],
        goalsCount: 0,
        notificationsCount: 0
      });
      const showGoalsCount = ref<number>(0);
      const showNotificationCount = ref<number>(5);
      const showBanner = ref<boolean>(true);
      const exploreActivitiesOffset = ref<number>(0);
      const myActivitiesOffset = ref<number>(0);
      const mobilePaginationPages = reactive({
        myActivitiesPage: 1,
        exploreActivitiesPage: 1
      }) as TIndexedObject;

      const {
        goalsList,
        childrenList,
        goalsCount,
        notificationsList,
        notificationsCount
      } = toRefs(state);

      const {
        upcomingActivitiesList,
        registeredActivitiesList,
        registeredCount,
        withoutRegistrationCount,

        fetchRegisteredActivities,
        fetchActivitiesWithoutRegistrations,
      } = useActivityRegistration();

      const userName = computed<string>(() => vuex.user?.attributes.name + ' ' + vuex.user?.attributes.family_name);

      function hideBanner() {
        showBanner.value = false;
      }

      function loadMoreActivities(value: string) {
        vuex.setLoading(true);

        if (value === 'registered') {
          mobilePaginationPages.myActivitiesPage += 1;
          fetchRegisteredActivities(
            registeredActivitiesParams(
              0,
              mobilePaginationPages.myActivitiesPage * ACTIVITIES_PAGINATION_LIMIT
            )
          )
            .finally(() => vuex.setLoading(false));
        } else {
          mobilePaginationPages.exploreActivitiesPage += 1;
          fetchActivitiesWithoutRegistrations(
            activitiesWithoutRegistrationsParams(
              0,
              mobilePaginationPages.exploreActivitiesPage * ACTIVITIES_PAGINATION_LIMIT
            )
          )
            .finally(() => vuex.setLoading(false));
        }
      }

      function resetMobilePagination() {
        mobilePaginationPages.myActivitiesPage = 1;
        mobilePaginationPages.exploreActivitiesPage = 1;
      }

      async function viewMoreGoals() {
        showGoalsCount.value += 2;
        await fetchMoreGoals(showGoalsCount.value);
      }

      async function viewMoreNotifications() {
        await fetchMoreNotifications(showNotificationCount.value);
        showNotificationCount.value += 3;
      }

      async function fetchMoreNotifications(offset = 0) {
        vuex.setLoading(true);

        return notificationsService.fetchNotifications(homeNotifications(3, offset))
          .then((res: INotificationRes[]) => {
            notificationsList.value = [...notificationsList.value, ...res];
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function fetchMoreGoals(offset = 0) {
        vuex.setLoading(true);

        return myGoalsService.fetchGoals({ status: 'opened' }, 2, offset)
          .then((res: IGoalsResponse) => {
            goalsList.value = [...goalsList.value, ...res.data];
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function refetchActivities(type: string, myOffset: number, exploreOffset: number) {
        vuex.setLoading(true);
        if (type === 'both') {
          myActivitiesOffset.value = myOffset;
          exploreActivitiesOffset.value = exploreOffset;
        } else if (type === 'myActivitiesPage') {
          myActivitiesOffset.value = myOffset;
        } else {
          exploreActivitiesOffset.value = exploreOffset;
        }

        return fetchActivities()
          .finally(() => vuex.setLoading(false));
      }

      async function fetchActivities() {
        vuex.setLoading(true);

        return Promise.all([
          fetchActivitiesWithoutRegistrations(
            activitiesWithoutRegistrationsParams(exploreActivitiesOffset.value)
          ),
          fetchRegisteredActivities(
            registeredActivitiesParams(myActivitiesOffset.value)
          )
        ])
          .finally(() => {
            resetMobilePagination();
            vuex.setLoading(false);
          });
      }

      async function fetchInitData() {
        vuex.setLoading(true);

        return Promise.all([
          childrenService.fetchChildren(),
          myGoalsService.fetchGoals({ status: 'opened' }, 2, 0),
          notificationsService.fetchNotifications(homeNotifications(5)),
          notificationsService.fetchNotificationsCount(),
          fetchActivities(),
        ])
          .then((res: any) => {
            const [children, goals, notifications, notificationsC] = res;
            goalsCount.value = goals.totalCount;
            childrenList.value = children;
            goalsList.value = goals.data;
            notificationsList.value = notifications;
            notificationsCount.value = notificationsC.count;
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));

      }

      onBeforeMount(() => { fetchInitData(); });

      return {
        childrenList,
        goalsList,
        upcomingActivitiesList,
        registeredActivitiesList,
        vuex,
        userName,
        goalsCount,
        notificationsList,
        notificationsCount,
        registeredCount,
        withoutRegistrationCount,

        showBanner,

        hideBanner,
        loadMoreActivities,
        viewMoreGoals,
        refetchActivities,
        viewMoreNotifications
      };
    }
  });
